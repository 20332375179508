.Boost-Screen {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  flex-grow: 1;
  height: 100%;
  width: 100%;
  padding-left: 5px;
}

.Selection-Field {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 5px;
}

.Selection-Images {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.Potion-Image {
  padding: 5px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.Prayer-Image {
  padding: 5px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}

.Other-Boost-Image {
  padding: 5px;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  align-items: center;
  justify-content: center;
}

.Selection-Title {
  width: 100%;
}

.Selection-Checkboxes {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}
