.Gear-Selection-Screen {
  display: flex;
  height: 90%;
  width: 100%;
  padding: 5px;
  justify-content: center;
  align-items: center;
}

.Equipment-Selection-Dropdowns {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 30%;
  padding: 5px;
  justify-content: flex-start;
}

.Equipment-Selection-Row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 5px;
}

.Equipment-And-Attack-Styles {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 70%;
  padding: 10px;
}

.Attack-Style-Choices {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  height: 25%;
  justify-content: space-between;
  align-items: space-between;
  padding: 5%;
  margin: 10px;
  background-color: rgba(0, 0, 0, 0.5);
}

.Attack-Name {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
  font-size: 18px;
  color: #DA8D2D;
  padding-bottom: 5px;
  text-shadow: 2px 2px 2px #3f2a14;
  width: 100%;
}

.Attack-Style-Option {
  display: flex;
  padding: 5%;
  height: 35%;
  width: 45%;
  background-color: rgba(100, 100, 100, 0.5);
  align-items: center;
  justify-content: center;
}

.Attack-Style-Name {
  color: #DA8D2D;
  text-shadow: 2px 2px 2px #3f2a14;
  font-weight: bold;
}

.Attack-Options-Popup {
  display: flex;
  flex-direction: column;
}

.Magic-Spell-Choices {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 70%;
  height: 50%;
  background-color: rgba(59, 49, 36, 0.7);
  border-style: solid;
  border-width: thick;
  border-color: #4B4445;
}

.Magic-Spell {
  display: flex;
  padding: 5px;
  align-items: center;
  justify-content: center;
}
